<template>
    <form-modal
        ref="formModal"
        :title="getI18nModified({ prefix: 'COMMON.filter', modifier: 2 })"
        @handleHide="handleHide">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-row>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nCategory"
                            label-for="filter-workday-justification"
                            class="label-pdv">
                            <multi-select
                                id="filter-workday-justification"
                                v-model="filterModal.workday_justification"
                                :options="categories"
                                track-by="value"
                                label="text"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nBusinessUnit"
                            label-for="filter-business-unit"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-business-unit'"
                                ref="businessUnitMultiselect"
                                v-model="filterModal.business_units"
                                :service="'business-units'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="filterBusinessUnitParameters"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nNeedsText"
                            label-for="filter-needs-text"
                            class="label-pdv">
                            <multi-select
                                id="filter-needs-text"
                                v-model="filterModal.needs_text"
                                :options="statuses"
                                track-by="value"
                                label="text"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nNeedsPhoto"
                            label-for="filter-needs-photo"
                            class="label-pdv">
                            <multi-select
                                id="filter-needs-photo"
                                v-model="filterModal.needs_photo"
                                :options="statuses"
                                track-by="value"
                                label="text"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nNeedsApproval"
                            label-for="filter-needs-approval"
                            class="label-pdv">
                            <multi-select
                                id="filter-needs-approval"
                                v-model="filterModal.needs_approval"
                                :options="statuses"
                                track-by="value"
                                label="text"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nMissingCheckin"
                            label-for="filter-missing-checkin"
                            class="label-pdv">
                            <multi-select
                                id="filter-missing-checkin"
                                v-model="filterModal.show_on_missing_checking"
                                :options="statuses"
                                track-by="value"
                                label="text"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nIncompleteCheckout"
                            label-for="filter-incomplete-checkout"
                            class="label-pdv">
                            <multi-select
                                id="filter-incomplete-checkout"
                                v-model="filterModal.show_on_incomplete_checkout"
                                :options="statuses"
                                track-by="value"
                                label="text"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nNewPos"
                            label-for="filter-new-pos"
                            class="label-pdv">
                            <multi-select
                                id="filter-new-pos"
                                v-model="filterModal.show_on_new_pos"
                                :options="statuses"
                                track-by="value"
                                label="text"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nAllowAbsenceOnCheckin"
                            label-for="filter-allow-absence-on-checkin"
                            class="label-pdv">
                            <multi-select
                                id="filter-allow-absence-on-checkint"
                                v-model="filterModal.allow_absence_on_checkin"
                                :options="statuses"
                                track-by="value"
                                label="text"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nAllowAbsenceOnCheckout"
                            label-for="filter-allow-absence-on-checkout"
                            class="label-pdv">
                            <multi-select
                                id="filter-allow-absence-on-checkout"
                                v-model="filterModal.allow_absence_on_checkout"
                                :options="statuses"
                                track-by="value"
                                label="text"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <save-button
                ref="okButton"
                :busy="submitLoading"
                :disabled="submitLoading"
                :title="getI18n('ACTION.filter')"
                @onClick="handleOk"
            />
            <clear-filter-button
                @onClick="handleClearFilter"
            />
        </template>
    </form-modal>
</template>

<script>
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import multiSelectWithService from '@src/components/multiSelectWithService'
import multiSelect from '@src/components/multiSelect'
import SaveButton from '@/src/components/SaveButton'
import ClearFilterButton from '@/src/components/ClearFilterButton'
import cloneObject from '@utils/clone-object'

const i18nKey = 'JUSTIFICATION'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        multiSelectWithService,
        multiSelect,
        SaveButton,
        ClearFilterButton
    },
    mixins: [validationMixin, swalFeedback, fieldsValidation],
    props: {
        handleSubmit: {
            type: Function,
            required: true,
            default: function () {},
        },
    },
    data() {
        return {
            i18nKey: i18nKey,
            i18nCommon: i18nCommon,
            filterModal: {},
            submitLoading: false,
            statuses: [
                {
                    value: true,
                    text: this.getI18n(i18nCommon, 'yes')
                },
                {
                    value: false,
                    text: this.getI18n(i18nCommon, 'no'),
                },
            ],
            categories: [
                {
                    value: false,
                    text: this.getI18n(i18nKey, 'TITLES.work_schedule_justification')
                },
                {
                    value: true,
                    text: this.getI18n(i18nKey, 'TITLES.workday_justification')
                },
            ],
            filterBusinessUnitParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
        }
    },
    validations: {},
    computed: {
        i18nCategory() {
            return this.getI18n(i18nCommon, 'category')
        },
        i18nBusinessUnit() {
            return this.getI18n(i18nCommon, 'business_unit')
        },
        i18nNeedsPhoto() {
            return this.getI18n(i18nKey, 'FLAGS.needs_photo')
        },
        i18nNeedsText() {
            return this.getI18n(i18nKey, 'FLAGS.needs_text')
        },
        i18nNeedsApproval() {
            return this.getI18n(i18nKey, 'FLAGS.needs_approval')
        },
        i18nMissingCheckin() {
            return this.getI18n(i18nKey, 'FLAGS.missing_checkin')
        },
        i18nIncompleteCheckout() {
            return this.getI18n(i18nKey, 'FLAGS.incomplete_checkout')
        },
        i18nNewPos() {
            return this.getI18n(i18nKey, 'FLAGS.new_pos')
        },
        i18nAllowAbsenceOnCheckin() {
            return this.getI18n(i18nKey, 'FLAGS.allow_absence_on_checkin')
        },
        i18nAllowAbsenceOnCheckout() {
            return this.getI18n(i18nKey, 'FLAGS.allow_absence_on_checkout')
        },
    },
    methods: {
        showModal(previousFilters) {
            this.filterModal = cloneObject(previousFilters);
            this.submitLoading = false
            this.$refs.formModal.show()
        },
        cleanModal() {
            this.filterModal = Object.assign(this.filterModal, {
                workday_justification: null,
                needs_text: null,
                needs_photo: null,
                needs_approval: null,
                show_on_missing_checking: null,
                show_on_incomplete_checkout: null,
                show_on_new_pos: null,
                allow_absence_on_checkin: null,
                allow_absence_on_checkout: null,
                business_units: [],
            })
        },
        handleHide() {
            if (!this.submitLoading) {
                this.cleanModal();
            }
        },
        handleOk() {
            if (this.submitLoading) {
                return;
            }

            this.submitLoading = true;
            this.$props.handleSubmit(this.filterModal);
            this.$refs.formModal.hide()
        },
        async handleClearFilter() {
            this.cleanModal()
        }
    },
}
</script>
