import BaseService from '@src/services/BaseService'
import axios from 'axios'

class JustificationTypeService extends BaseService {
    constructor() {
        super('justification-types')
    }

    fetchAll(params) {
        return axios.post(`${this.getUrl()}`, params)
    }

    sync(id, params) {
        return this.axios.post(`${this.getUrl()}/${id}/sync`, params)
    }
}

export default new JustificationTypeService()
