<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue';
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import Layout from '@layouts/main'
import pagination from '@src/mixins/pagination'
import FilterBar from '@src/components/FilterBar'
import swalFeedbackVue from '@src/mixins/swalFeedback.vue'
import vuetableFormatter from '@src/mixins/vuetableFormatters'
import JustificationTypeService from '@src/services/JustificationTypeService'
import JustificationTypeModal from '@views/justificationType/justificationTypeModal.vue'
import JustificationTypeFilterModal from '@views/justificationType/justificationTypeFilterModal'
import GridFormModal from '@src/components/GridFormModal.vue'
import AddLabelButton from '@/src/components/AddLabelButton.vue'
import ExportButton from '@/src/components/ExportButton.vue'
import CountTableButton from '@/src/components/CountTableButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import { debounce } from 'lodash'

const i18nCommon = 'COMMON'
const i18nKey = 'JUSTIFICATION'

export default {
    components: {
        JustificationTypeModal,
        JustificationTypeFilterModal,
        GridFormModal,
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        Layout,
        FilterBar,
        AddLabelButton,
        ExportButton,
        CountTableButton,
        MoreTableButton,
        FilterLabelButton,
    },
    mixins: [
        pagination,
        swalFeedbackVue,
        filterVuetable,
        vuetableFormatter
    ],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            i18nCommon: i18nCommon,
            i18nKey: i18nKey,
            filters: {
                workday_justification: null,
                needs_text: null,
                needs_photo: null,
                needs_approval: null,
                show_on_missing_checking: null,
                show_on_incomplete_checkout: null,
                show_on_new_pos: null,
                allow_absence_on_checkin: null,
                allow_absence_on_checkout: null,
                business_units: [],
            },
            modal: {
                id: 0,
                name: '',
                business_units: [],
                is_global: true,
                workday_justification: false,
                needs_photo: false,
                needs_text: false,
                needs_approval: false,
                show_on_missing_checking: false,
                show_on_incomplete_checkout: false,
                show_on_new_pos: false,
                allow_absence_on_checkin: false,
                allow_absence_on_checkout: false,
            },
            modalIsEdit: false,
            modalHasResponseError: false,
            data: [],
            perPage: 10,
            fields: [
                {
                    name: 'name',
                    title: this.getI18n(i18nKey, 'TITLES.justification_type'),
                    sortField: 'name',
                },
                {
                    name: 'workday_justification',
                    title: this.getI18n(i18nCommon, 'category'),
                    sortField: 'workday_justification',
                },
                {
                    name: 'needs_text',
                    title: this.getI18n(i18nKey, 'FLAGS.needs_text'),
                    sortField: 'needs_text',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'needs_photo',
                    title: this.getI18n(i18nKey, 'FLAGS.needs_photo'),
                    sortField: 'needs_photo',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'needs_approval',
                    title: this.getI18n(i18nKey, 'FLAGS.needs_approval'),
                    sortField: 'needs_approval',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'show_on_missing_checking',
                    title: this.getI18n(i18nKey, 'FLAGS.missing_checkin'),
                    sortField: 'show_on_missing_checking',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'show_on_incomplete_checkout',
                    title: this.getI18n(i18nKey, 'FLAGS.incomplete_checkout'),
                    sortField: 'show_on_incomplete_checkout',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'show_on_new_pos',
                    title: this.getI18n(i18nKey, 'FLAGS.new_pos'),
                    sortField: 'show_on_new_pos',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'allow_absence_on_checkin',
                    title: this.getI18n(i18nKey, 'FLAGS.allow_absence_on_checkin'),
                    sortField: 'allow_absence_on_checkin',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'allow_absence_on_checkout',
                    title: this.getI18n(i18nKey, 'FLAGS.allow_absence_on_checkout'),
                    sortField: 'allow_absence_on_checkout',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'business_units',
                    title: this.getI18nModified({
                        prefix: i18nCommon,
                        suffix: 'business_unit',
                        modifier: 2,
                    })
                },
                {
                    name: 'updated_at',
                    title: this.getI18n(i18nCommon, 'updated_at'),
                    sortField: 'updated_at',
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            fieldsBusinessUnitsModal: [
                {
                    key: 'name',
                    label: this.getI18n('COMMON.business_unit'),
                    sortable: true,
                },
            ],
            css: {},
            filterText: '',
            additionalParameters: {
                with: ['businessUnits'],
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.justification',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.justification_type',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nNewJustificationType() {
            return this.getI18n(i18nKey, 'BUTTONS.new_justification_type')
        },
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters.search = ''
            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },
    methods: {
        createAdditionalParameters() {
            this.additionalParameters.search = ''

            const search = this.createSearch({
                name: this.filterText && this.filterText.length > 0
                    ? this.filterText
                    : null,
                workday_justification: this.filters.workday_justification
                    ? String(this.filters.workday_justification.value)
                    : null,
                needs_text: this.filters.needs_text
                    ? String(this.filters.needs_text.value)
                    : null,
                needs_photo: this.filters.needs_photo
                    ? String(this.filters.needs_photo.value)
                    : null,
                needs_approval: this.filters.needs_approval
                    ? String(this.filters.needs_approval.value)
                    : null,
                show_on_missing_checking: this.filters.show_on_missing_checking
                    ? String(this.filters.show_on_missing_checking.value)
                    : null,
                show_on_incomplete_checkout: this.filters.show_on_incomplete_checkout
                    ? String(this.filters.show_on_incomplete_checkout.value)
                    : null,
                show_on_new_pos: this.filters.show_on_new_pos
                    ? String(this.filters.show_on_new_pos.value)
                    : null,
                allow_absence_on_checkin: this.filters.allow_absence_on_checkin
                    ? String(this.filters.allow_absence_on_checkin.value)
                    : null,
                allow_absence_on_checkout: this.filters.allow_absence_on_checkout
                    ? String(this.filters.allow_absence_on_checkout.value)
                    : null,
            })

            return this.formatParameters({
                with: ['businessUnits'],
                search,
                business_units: this.filters.business_units.map((businessUnit) => businessUnit.id)
            })
        },
        i18nJustificationType(modifier) {
            return this.getI18nModified({
                prefix: 'JUSTIFICATION.TITLES',
                suffix: 'justification_type',
                modifier: modifier,
            })
        },

        reloadTable() {
            this.$refs.vuetable.reload()
        },

        refreshTable() {
            this.$refs.vuetable.refresh()
        },

        fetch(url, params) {
            return JustificationTypeService.fetchVuetable(url, params)
        },

        cleanModal() {
            this.$nextTick(() => this.$refs.justificationTypeModal.cleanModal())
        },
        showModal() {
            this.$nextTick(() => this.$refs.justificationTypeModal.showModal())
        },

        replaceModalData(data) {
            Object.assign(this.modal, data)

            Object.assign(this.modal, {
                business_units: []
            })

            Object.assign(this.modal.business_units, data.business_units)
        },

        filter(appliedFilters) {
            this.filters = appliedFilters
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshTable()
        },

        showFilterModal() {
            this.$nextTick(() =>
                this.$refs.justificationTypeFilterModal.showModal(this.filters)
            )
        },

        openModalToCreate() {
            this.modalIsEdit = true
            this.cleanModal()
            this.showModal()
        },

        openModalToEdit(role) {
            this.modalIsEdit = false
            this.replaceModalData(role)
            this.showModal()
        },
        openModalBusinessUnits(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.gridBusinessUnitsModal.showModal())
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText"></filter-bar>
                    <filter-label-button @onClick="showFilterModal" />
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <add-label-button
                            v-if="$can('CREATE', 'JUSTIFICATION')"
                            :title="i18nNewJustificationType"
                            @onClick="openModalToCreate"
                        />
                        <export-button
                            v-if="$can('CREATE', 'EXPORTJOB')"
                            type="justification_type"
                            :create-parameters="createAdditionalParameters"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nJustificationType(2)" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="workday_justification" slot-scope="props">
                            {{ props.rowData.workday_justification ? getI18n(i18nKey, 'TITLES.workday_justification') : getI18n(i18nKey, 'TITLES.work_schedule_justification')  }}
                        </template>
                        <template slot="business_units" slot-scope="props">
                            <count-table-button :value="props.rowData.business_units.length" @onClick="openModalBusinessUnits(props.rowData)" />
                        </template>
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <more-table-button @onClick="openModalToEdit(props.rowData)" />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <justification-type-modal
            ref="justificationTypeModal"
            :modal-is-edit="modalIsEdit"
            :modal="modal"
            @refresh="reloadTable"
        />
        <grid-form-modal
            ref="gridBusinessUnitsModal"
            :modal="modal"
            :items="modal.business_units"
            :fields="fieldsBusinessUnitsModal"
            :title="getI18nModified({
                        prefix: 'COMMON',
                        suffix: 'business_unit',
                        modifier: 2,
                    })"
        />
        <justification-type-filter-modal ref="justificationTypeFilterModal" :handle-submit="filter" />
    </Layout>
</template>
