<template>
    <form-modal
        ref="formModal"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        size="xl"
        :handle-close="handleCancel">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nJustificationType"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nJustificationType"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline">
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                v-if="$can('UPDATE', 'JUSTIFICATION')"
                                :title="getI18n(i18nKey, 'TITLES.justification_type')"
                                :disabled="deleteLoading"
                                @onClick="isEdit = true"
                            />
                           <delete-label-button
                                v-if="$can('DELETE', 'JUSTIFICATION')"
                                :title="getI18n(i18nKey, 'TITLES.justification_type')"
                                :busy="deleteLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <b-tabs
                    id="businessunit-tabs"
                    v-model="tabIndex"
                    class="tabs-pdv"
                    @activate-tab="tabActivated">
                    <b-tab :title="i18nInformation">
                        <b-row>
                            <b-col class="col-sm-12 col-md-9">
                                <b-form-group
                                    class="required label-pdv"
                                    :label="i18nName"
                                    label-for="name-input"
                                    :invalid-feedback="i18nJustificationInvalidNameLabel">
                                    <b-form-input
                                        id="name-input"
                                        v-model.trim="$v.justificationTypeModal.name.$model"
                                        class="input-pdv-blue"
                                        name="name"
                                        type="text"
                                        :disabled="!isEdit || submitLoading"
                                        :state="validateField('name', 'justificationTypeModal')"
                                        autofocus
                                        autocomplete="off"
                                        @input="clearResponseError('name', 'justificationTypeModal')"
                                        @blur="$v.justificationTypeModal.name.$touch;"
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-sm-12 col-md-3">
                                <b-form-group
                                    class="required label-pdv"
                                    :label="getI18n('COMMON', 'category')"
                                    label-for="workday-justification"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_category')">
                                    <b-form-input
                                        v-if="isJustificationTypeCreated"
                                        v-model="i18nCategoryName"
                                        class="input-pdv-blue"
                                        name="workday-justification"
                                        type="text"
                                        disabled
                                    />
                                    <multi-select
                                        v-else
                                        id="workday-justification"
                                        v-model="category"
                                        :options="categories"
                                        label="label"
                                        :searchable="false"
                                        :disabled="isJustificationTypeCreated || submitLoading"
                                        :select-class="validationClass($v.justificationTypeModal.workday_justification)"
                                        @input="changeCategory"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="6">
                                <topic-header
                                    :caption="getI18n('JUSTIFICATION', 'FLAGS.parameters')"
                                    icon="fe-settings"
                                />
                                <b-row>
                                    <b-col class="col-sm-4 col-md-4">
                                        <b-form-group
                                            class="label-pdv"
                                            :label="getI18n('JUSTIFICATION.FLAGS', 'needs_text')"
                                            label-for="needs-text"
                                        >
                                            <b-form-checkbox
                                                id="needs-text"
                                                v-model="justificationTypeModal.needs_text"
                                                size="lg"
                                                switch
                                                :disabled="!isEdit"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col class="col-sm-4 col-md-4">
                                        <b-form-group
                                            class="label-pdv"
                                            :label="getI18n('JUSTIFICATION.FLAGS', 'needs_photo')"
                                            label-for="needs-photo"
                                        >
                                            <b-form-checkbox
                                                id="needs-photo"
                                                v-model="justificationTypeModal.needs_photo"
                                                size="lg"
                                                switch
                                                :disabled="!isEdit"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col class="col-sm-4 col-md-4">
                                        <b-form-group
                                            class="label-pdv"
                                            :label="getI18n('JUSTIFICATION.FLAGS', 'needs_approval')"
                                            label-for="needs-approval"
                                        >
                                            <b-form-checkbox
                                                id="needs-approval"
                                                v-model="justificationTypeModal.needs_approval"
                                                size="lg"
                                                switch
                                                :disabled="!isEdit"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col md="6">
                                <topic-header
                                    :caption="getI18n('JUSTIFICATION', 'FLAGS.exibition')"
                                    icon="fe-eye"
                                />
                                <b-row>
                                    <b-col class="col-sm-4 col-md-4">
                                        <b-form-group
                                            class="label-pdv"
                                            :label="getI18n('JUSTIFICATION.FLAGS', 'missing_checkin')"
                                            label-for="missing-checkin"
                                        >
                                            <b-form-checkbox
                                                id="missing-checkin"
                                                v-model="justificationTypeModal.show_on_missing_checking"
                                                size="lg"
                                                switch
                                                :disabled="!isEdit"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col class="col-sm-4 col-md-4">
                                        <b-form-group
                                            class="label-pdv"
                                            :label="getI18n('JUSTIFICATION.FLAGS', 'incomplete_checkout')"
                                            label-for="incomplete-checkout"
                                        >
                                            <b-form-checkbox
                                                id="incomplete-checkout"
                                                v-model="justificationTypeModal.show_on_incomplete_checkout"
                                                size="lg"
                                                switch
                                                :disabled="!isEdit"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col class="col-sm-4 col-md-4">
                                        <b-form-group
                                            class="label-pdv"
                                            :label="getI18n('JUSTIFICATION.FLAGS', 'new_pos')"
                                            label-for="new-pos"
                                        >
                                            <b-form-checkbox
                                                id="new-pos"
                                                v-model="justificationTypeModal.show_on_new_pos"
                                                size="lg"
                                                switch
                                                :disabled="!isEdit"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="12">
                                <topic-header
                                    :caption="getI18n('JUSTIFICATION', 'FLAGS.allow_absence')"
                                    icon="fe-x-square"
                                />
                                <b-row>
                                    <b-col class="col-sm-3 col-md-3">
                                        <b-form-group
                                            class="label-pdv"
                                            :label="getI18n('JUSTIFICATION', 'FLAGS.allow_absence_on_checkin')"
                                            label-for="allow-absence-on-checkin"
                                        >
                                            <b-form-checkbox
                                                id="allow-absence-on-checkin"
                                                v-model="justificationTypeModal.allow_absence_on_checkin"
                                                size="lg"
                                                switch
                                                :disabled="!isEdit"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col class="col-sm-3 col-md-3">
                                        <b-form-group
                                            class="label-pdv"
                                            :label="getI18n('JUSTIFICATION', 'FLAGS.allow_absence_on_checkout')"
                                            label-for="allow-absence-on-checkout"
                                        >
                                            <b-form-checkbox
                                                id="allow-absence-on-checkout"
                                                v-model="justificationTypeModal.allow_absence_on_checkout"
                                                size="lg"
                                                switch
                                                :disabled="!isEdit"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="i18nBusinessUnits">
                        <b-row>
                            <b-col v-if="isEdit" class="col-sm-12 col-md-4">
                                <b-form-group
                                    :label="getI18n('COMMON.business_unit')"
                                    label-for="add-business-unit-input"
                                    class="label-pdv" >
                                    <multiSelectWithService
                                        :id="'add-business-unit-input'"
                                        ref="businessUnitMultiselect"
                                        v-model="businessUnitName"
                                        :service="'business-units'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col :class="isEdit ? 'col-sm-12 col-md-8' : 'col-sm-12 col-md-12'">
                                <data-mode-vuetable
                                    ref="relationVuetable"
                                    v-model="justificationTypeModal.business_units"
                                    :fields="fields"
                                    :display-delete-button="isEdit"
                                    :disabled="submitLoading"
                                    @onClickDelete="handleRemoveBusinessUnit"
                                />
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="tabIndex !== 1"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON.next')"
                    :busy="submitLoading"
                    @onClick="tabIndex++"
                />
                <save-button
                    v-else-if="isJustificationTypeCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import { minLength, required } from 'vuelidate/lib/validators'
import JustificationTypeService from '@src/services/JustificationTypeService'
import BusinessUnitsService from '@src/services/BusinessUnitsService'
import FormModal from '@/src/components/FormModal.vue'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import multiSelect from '@components/multiSelect'
import multiSelectWithService from '@src/components/multiSelectWithService.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import DataModeVuetable from '@/src/components/DataModeVuetable.vue'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'
import TopicHeader from '@src/components/TopicHeader.vue'

const i18nCommon = 'COMMON'
const i18nKey = 'JUSTIFICATION'

export default {
    components: {
        multiSelect,
        multiSelectWithService,
        FormModal,
        ConfirmationFormModal,
        FeedbackModal,
        CancelButton,
        AddButton,
        SaveButton,
        DataModeVuetable,
        EditLabelButton,
        DeleteLabelButton,
        TopicHeader,
    },
    mixins: [validationMixin, swalFeedback, fieldsValidation],
    props: {
        modalIsEdit: {
            required: true,
            type: Boolean,
        },
        modal: {
            type: Object,
            default: () => {
                return {
                    id: 0,
                    name: '',
                    business_units: [],
                    is_global: true,
                    workday_justification: false,
                    needs_photo: false,
                    needs_text: false,
                    needs_approval: false,
                    show_on_missing_checking: false,
                    show_on_incomplete_checkout: false,
                    show_on_new_pos: false,
                    allow_absence_on_checkin: false,
                    allow_absence_on_checkout: false,
                }
            },
        },
    },
    data() {
        return {
            i18nKey: i18nKey,
            i18nCommon: i18nCommon,
            justificationTypeModal: this.modal,
            tabIndex: 0,
            isEdit: false,
            submitLoading: false,
            deleteLoading: false,
            category: false,
            businessUnitName: '',
            addAllBusinessUnit: false,
            fields: [
                {
                    key: 'name',
                    label: this.getI18n('COMMON.business_unit'),
                    sortable: true,
                },
                {
                    key: 'contract_time',
                    label: this.getI18n('TENANT.FIELDS.minimum_time_store'),
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
            categories: [
                {
                    id: false,
                    label: this.getI18n(i18nKey, 'TITLES.work_schedule_justification')
                },
                {
                    id: true,
                    label: this.getI18n(i18nKey, 'TITLES.workday_justification')
                },
            ]
        }
    },
    validations: {
        justificationTypeModal: {
            name: {
                required,
                minLength: minLength(2),
            },
            workday_justification: {
                required,
            },
        },
    },
    computed: {
        isJustificationTypeCreated() {
            return this.justificationTypeModal.id > 0
        },
        i18nTitleModal() {
            if (this.isJustificationTypeCreated) {
                if (this.isEdit) {
                    return `${this.getI18n(i18nCommon, 'edit')} ${this.i18nJustificationType}`
                }
                return this.justificationTypeModal.name
            }
            else {
                return this.getI18n(i18nKey, 'BUTTONS.new_justification_type')
            }
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nCategoryName() {
            return this.justificationTypeModal.workday_justification ?
                this.getI18n(i18nKey, 'TITLES.workday_justification') :
                this.getI18n(i18nKey, 'TITLES.work_schedule_justification')
        },
        i18nJustificationInvalidNameLabel() {
            return this.getI18n('ERROR_CODES.invalid_name')
        },
        modalOperation() {
            return this.isJustificationTypeCreated ? 'edited' : 'created'
        },
        i18nJustificationType() {
            return this.getI18nModified({
                prefix: i18nKey + '.TITLES.justification_type',
                modifier: 1,
            })
        },
        i18nInformation() {
            return this.getI18nModified({
                prefix: 'COMMON.info',
                modifier: 2,
            })
        },
        i18nBusinessUnits() {
            return this.getI18nModified({
                suffix: 'TITLES.business_unit',
                prefix: 'BUSINESS_UNITS',
                modifier: 2,
            })
        },
    },
    watch: {
        businessUnitName(value) {
            if (value) {
                const index = this.justificationTypeModal.business_units.findIndex(find => find.id === value.id);
                if (index < 0) {
                    this.justificationTypeModal.business_units.push(value)
                }
                this.businessUnitName = ''
                this.$refs.businessUnitMultiselect.$el.focus()
            }
        },
        async addAllBusinessUnit(value) {
            if (value) {
                this.justificationTypeModal.business_units.splice(0, this.justificationTypeModal.business_units.length)

                const response = await BusinessUnitsService.fetchAll()
                if (response.status === 200) {
                    response.data.data.forEach(unit => this.justificationTypeModal.business_units.push(unit));
                }
                this.addAllBusinessUnit = false
            }
        }
    },
    methods: {
        tabActivated(newTabIndex, oldTabIndex, event) {
            if (this.isEdit) {
                if (oldTabIndex === 0) {
                    this.$v.justificationTypeModal.$touch()
                    if (this.$v.justificationTypeModal.$invalid) {
                        event.preventDefault();
                    }
                }
            }
        },
        changeCategory(option) {
            this.$v.justificationTypeModal.workday_justification.$model = option ? option.id : null
            this.$v.justificationTypeModal.workday_justification.$touch()
        },
        toggleProperty(property) {
            this.justificationTypeModal[property] = !this
                .justificationTypeModal[property]
        },
        showModal() {
            this.isEdit = this.modalIsEdit
            this.tabIndex = 0
            this.submitLoading = false
            this.deleteLoading = false
            this.category = this.categories.find((category) => category.id === this.modal.workday_justification)
            this.businessUnitName = ''

            this.$refs.formModal.show()
            this.resetValidation()
        },
        clearAllResponseError() {
            this.modalResponseErrors = {}
        },
        cleanModal() {
            this.justificationTypeModal = Object.assign(
                this.justificationTypeModal,
                {
                    id: 0,
                    name: '',
                    business_units: [],
                    is_global: true,
                    workday_justification: false,
                    needs_photo: false,
                    needs_text: false,
                    needs_approval: false,
                    show_on_missing_checking: false,
                    show_on_incomplete_checkout: false,
                    show_on_new_pos: false,
                    allow_absence_on_checkin: false,
                    allow_absence_on_checkout: false,
                }
            )
            this.$v.justificationTypeModal.$reset()
        },
        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        handleOk() {
            this.$v.justificationTypeModal.$touch()
            if (!this.$v.justificationTypeModal.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },
        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const modalInfo = {
                ...this.justificationTypeModal,
            }
            modalInfo.business_units = this.justificationTypeModal.business_units.map(
                (businessUnit) => businessUnit.id
            )
            const operation = this.isJustificationTypeCreated
                ? JustificationTypeService.update(modalInfo.id, modalInfo)
                : JustificationTypeService.create(modalInfo)

            const response = await operation
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(
                    this.i18nJustificationType,
                    this.modalOperation,
                    0
                )
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            }

            this.submitLoading = false
        },
        handleDelete() {
            if (this.deleteLoading) {
                return
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteTypeJustification)
        },
        async deleteTypeJustification(deleteRecord) {
            if (!deleteRecord) {
                return
            }

            this.$v.justificationTypeModal.$touch()
            this.deleteLoading = true
            const response = await JustificationTypeService.delete(this.justificationTypeModal.id)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nJustificationType, 'deleted', 3)
            } else if (statusCode === '417') {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }

            this.deleteLoading = false
        },
        async handleRemoveBusinessUnit(id) {
            const index = this.justificationTypeModal.business_units.findIndex(find => find.id === id);

            if (index > -1) {
                this.justificationTypeModal.business_units.splice(index, 1);
            }
        },
    },
}
</script>
